/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import accordion from '../components/layout/accordion';

export default class FAQ {
  constructor() {
    this.accordionsWrapper = document.querySelector('.js-faq-wrapper');
    this.templateFAQ = `<div class="accordion">
          <h3 class="accordion__title">[question]</h3>
          <div class="accordion__content">
              [answer]
          </div>
      </div>`;

    this.fetchData();
  }

  async fetchData() {
    const url = 'https://www.aquaflanders.be/kraanwater-faq.json';
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();
      if (json.data && json.data[0].faqs && json.data[0].faqs.length > 0) {
        const faqsWithoutCat = json.data[0].faqs.filter(faq => !faq.category || faq.category === '');
        const faqsWithCat = json.data[0].faqs.filter(faq => faq.category !== '');
        this.renderFAQs(faqsWithoutCat);
        this.groupCategories(faqsWithCat);

        accordion();
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  groupCategories(data) {
    const groupedFaqs = data.reduce((acc, faq) => {
      if (!acc[faq.category]) {
        acc[faq.category] = [];
      }
      acc[faq.category].push({ question: faq.question, answer: faq.answer });

      return acc;
    }, {});


    for (const category in groupedFaqs) {
      const title = document.createElement('h2');
      title.classList.add('h3');
      title.textContent = category;
      this.accordionsWrapper.appendChild(title);

      this.renderFAQs(groupedFaqs[category]);
    }
  }

  renderFAQs(data) {
    const faqs = data.map(faq => this.templateFAQ
      .replace('[question]', faq.question)
      .replace('[answer]', faq.answer));

    const wrapper = document.createElement('div');
    wrapper.classList.add('accordion-section');
    wrapper.innerHTML = faqs.join('');

    this.accordionsWrapper.appendChild(wrapper);
  }
}
