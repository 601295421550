import $ from 'jquery';
import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl';

export default function () {
  const flatpickrValue = $('.flatpickr').val();

  flatpickr('.flatpickr', {
    altInput: true,
    altFormat: 'j F Y',
    dateFormat: 'Y-m-d',
    locale: Dutch,
    maxDate: 'today',
    defaultDate: flatpickrValue || 'today',
  });
}
