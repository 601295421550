import $ from 'jquery';

export default function () {
  $('.js-back-to-top').click((e) => {
    e.preventDefault();
    $('body, html').animate({
      scrollTop: 0,
    }, 500);
  });
}
