export default function () {
  const trackingTargets = document.querySelectorAll('[data-gtm-track]');
  window.dataLayer = window.dataLayer || [];

  [...trackingTargets].forEach((target) => {
    target.addEventListener('click', () => {
      let buttonTitle;
      let event;
      if (target.dataset.title) {
        buttonTitle = target.dataset.title;
        event = 'download';
      } else {
        buttonTitle = target.previousElementSibling.innerHTML;
        event = target.hasAttribute('download') ? 'download' : 'button';
      }

      window.dataLayer.push({
        event,
        [`${event}Title`]: buttonTitle,
        [`${event}Label`]: target.textContent,
      });
    });
  });
}
