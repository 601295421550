import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
import gaTracking from './components/tracking/gatracking';
import trackingEvents from './components/tracking/trackingEvents';

import Contact from './pages/Contact';
import SchoolsMap from './pages/SchoolsMap';
import Faq from './pages/FAQ';
import flatpickr from './components/layout/flatpickr';
import scrollToTop from './components/layout/scrollToTop';
import accordion from './components/layout/accordion';

import ModalManager from './components/modals/ModalManager';
import popups from './components/popups/popups';

require('./utils/nativeConsole');
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// eslint-disable-next-line
window.modalManager = new ModalManager();

// Layout setup
navigation();
responsiveVideos();
flatpickr();
scrollToTop();
accordion();
popups();

// Forms
validation();

// Tracking
gaTracking();
trackingEvents();

const newsletterForm = new RecaptchaForm('#form-newsletter');
window.submitRecaptchaFormNewsletter = () => {
  newsletterForm.submitCallback();
};

const downloadForm = new RecaptchaForm('#form-download');
window.submitRecaptchaFormDownload = () => {
  downloadForm.submitCallback();
};

const compassForm = new RecaptchaForm('#form-compass');
window.submitRecaptchaFormCompass = () => {
  compassForm.submitCallback();
};

const contactForm = new RecaptchaForm('#form-contact');
window.submitRecaptchaFormContact = () => {
  contactForm.submitCallback();
};

const recipeForm = new RecaptchaForm('#form-recipe');
window.submitRecaptchaFormRecipe = () => {
  recipeForm.submitCallback();
};

const contestForm = new RecaptchaForm('#form-contest');
window.submitRecaptchaFormContest = () => {
  contestForm.submitCallback();
};


// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
  SchoolsMap,
  Faq,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    // Exceptional use of new
    // eslint-disable-next-line no-new
    new pages[pageClassName]();
  }
}

window.modalManager.checkModalOnPageLoad();
