export default function () {
  const popup = document.querySelector('.js-compass-modal');
  const closeBtns = document.querySelectorAll('.js-close-modal');

  closeBtns.forEach((button) => {
    button.addEventListener('click', () => {
      popup.classList.remove('active');
    });
  });

  if (popup) {
    setTimeout(() => {
      popup.classList.add('active');
    }, 500);
  }
}
