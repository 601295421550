import BasicGoogleMap from '../components/maps/BasicGoogleMap';

export default class Contact {
  constructor() {
    this.init();
  }

  init() {
    const mapEl = document.getElementById('map');
    if (window.schoolLocations && mapEl) {
      const map = new BasicGoogleMap();
      map.init();

      const markers = [];
      window.schoolLocations.forEach((schoolLocation) => {
        // eslint-disable-next-line
        markers.push(map.addMarker(schoolLocation.latitude, schoolLocation.longitude, schoolLocation.name, schoolLocation.address));
      });

      const google = window.google || {};
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => {
        bounds.extend(marker.getPosition());
      });
      map.map.fitBounds(bounds);
    }
  }
}
